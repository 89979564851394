<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
-->

<template>
    <div style="text-align: center;">
        <h3>wow交流①群</h3>
        <div style="display: flex;justify-content: center;align-items: center;">
            <img :src="img" width="400" height="400" alt="">
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
    components: {

    },
    data() {
        return {
            img: ""
        }
    },
    computed: {
        ...mapGetters("user", ["userInfo"]),
        ...mapGetters("app", ["config"])

    },
    created() {
        this.img = 'https://img.wowai.fun' + this.config.wx_code
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.layout-default .ant-layout-header {
    display: none !important;
}
</style>
